import axiosInstance from '.'

export async function initializeEmailAddressOwnershipVerification(email: string, product: string): Promise<void> {
  const body = {
    email: email,
    product: product,
  }

  await axiosInstance.post('/email-address-ownership/initialize', body)
}

export async function verifyEmailAddressOwnership(
  email: string,
  otpCode: string,
  trustCurrentBrowser: boolean,
): Promise<void> {
  const body = {
    email: email,
    otpCode: otpCode,
    trustCurrentBrowser: trustCurrentBrowser,
  }

  await axiosInstance.post('/email-address-ownership/verify', body)
}
