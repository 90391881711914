import { useContext } from 'react'
import { Typography } from '@papercutsoftware/pcds-react'
import { ProductContext } from '@/context/product'
import { AuthFlowContext, AuthFlow } from '@/context/authflow'

interface LoginSignUpPageHeadingProps {
  tenantName?: string
}

const LoginSignUpPageHeading = ({ tenantName }: LoginSignUpPageHeadingProps) => {
  const authFlow = useContext(AuthFlowContext)
  const product = useContext(ProductContext)
  let titlePrefix = 'Log in'
  if (authFlow === AuthFlow.Signup) {
    titlePrefix = 'Sign up'
  }

  return (
    <Typography color="heading" variant="h2" component="h2" data-testid="page-heading">
      {titlePrefix} to {tenantName ? tenantName : product.getName()}
    </Typography>
  )
}

export default LoginSignUpPageHeading
