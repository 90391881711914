'use client'

import { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { Loader, OnboardingTemplate, Stack, SubmitButton, TextField } from '@papercutsoftware/pcds-react'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { getTenantsByEmailAddress, getTenantsByExternalEntity, Tenant, UserType } from '@/api/tenants'
import EmailOwnershipVerification from '@/components/EmailOwnershipVerification/EmailOwnershipVerification'
import LoginSignUpPageHeading from '@/components/LoginSignUpPageHeading/LoginSignUpPageHeading'
import ProductSelector from '@/components/ProductSelector/ProductSelector'
import { renderError } from '@/components/RenderAlert/RenderErrorAlert'
import TenantLogin from '@/components/TenantLogin/TenantLogin'
import TenantSelector from '@/components/TenantSelector/TenantSelector'
import { ProductContext, unsetProductId } from '@/context/product'
import { StyledFormWrapper } from '@/styles/firebaseUi.styles'
import { isValidEmail } from '@/utils/emailValidation'
import { toMessage } from '@/utils/errorCodes'
import { errorQueryParam, tenantIDQueryParam } from '@/utils/pageurl/pageurl'

const Home: FC = () => {
  const router = useRouter()
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const searchParams = useSearchParams()
  const tenantId = searchParams.get(tenantIDQueryParam)
  const externalEntityId = searchParams.get('externalEntityId') as string
  const dataCenter = searchParams.get('dataCenter') as string

  const product = useContext(ProductContext)
  const productId = product.getId()

  // ExternalEntityID and DataCenter provided typically from sharing tenant specific login link without knowing tenantID
  useEffect(() => {
    const fetchTenantByExternalEntity = async () => {
      setIsLoading(true)
      try {
        const response = await getTenantsByExternalEntity(externalEntityId, dataCenter, productId)
        if (response?.tenants.length) {
          setSelectedTenant(response.tenants[0].id)
        } else {
          console.error('fetchTenantByExternalEntity: no tenant found')
        }
      } catch (error) {
        console.error('fetchTenantByExternalEntity', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (externalEntityId && dataCenter && productId) {
      fetchTenantByExternalEntity()
    }
  }, [externalEntityId, dataCenter, productId])

  // TenantID populated from URL for tenant specific login or after tenant is selected from multi-tenant login
  useEffect(() => {
    setSelectedTenant(tenantId)
  }, [tenantId])

  const renderLogin = () => {
    if (!router.isReady || isLoading) {
      return <Loader />
    }

    if (productId === unsetProductId) {
      return <ProductSelector />
    }

    if (selectedTenant) {
      return <TenantLogin tenantId={selectedTenant} />
    }

    return <GlobalLogin />
  }

  return (
    <OnboardingTemplate termsOfServiceUrl={product.getTermsOfServiceUrl()} product={productId} topRightContent={<></>}>
      {renderLogin()}
    </OnboardingTemplate>
  )
}

const GlobalLogin = () => {
  const router = useRouter()
  const product = useContext(ProductContext)

  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tenantsToSelect, setTenantsToSelect] = useState<Tenant[]>([])
  const [isEmailOwnershipVerificationRequired, setIsEmailOwnershipVerificationRequired] = useState<boolean>(false)

  const searchParams = useSearchParams()
  const urlErrorMsg = searchParams.get(errorQueryParam) ? toMessage(searchParams.get(errorQueryParam)!) : null

  const handleGetTenantsByEmailAddress = async () => {
    const res = await getTenantsByEmailAddress(email, product.getId())
    const tenants = res?.tenants

    if (res && tenants?.length) {
      const singleTenant = tenants?.length === 1
      const multipleTenants = tenants?.length && tenants.length > 1

      if (singleTenant) {
        const { id: tenantId, userType } = tenants[0]
        const query = {
          ...router.query,
          tenantId,
          prefilledEmail: email,
          ...(userType === UserType.PreSignInUser && { signup: '' }),
        }

        router.push({ query })
      }

      if (multipleTenants) {
        setTenantsToSelect(tenants)
        setIsEmailOwnershipVerificationRequired(res.emailOwnershipVerificationRequired)
      }
    } else {
      setError(
        `Please check you have entered your email address correctly and your organization uses ${product.getName()}`,
      )
    }
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    setError(null)
    clearErrorFromURL()
    if (!validateEmail()) return

    setIsLoading(true)

    try {
      await handleGetTenantsByEmailAddress()
    } catch (error) {
      setError('Error fetching tenants.')
    } finally {
      setIsLoading(false)
    }
  }

  const validateEmail = () => {
    if (email.trim() === '') {
      setError('Email address is required.')

      return false
    }

    if (!isValidEmail(email)) {
      setError('Enter a valid email address.')

      return false
    }

    return true
  }

  const clearErrorFromURL = () => {
    if (router.query.error) {
      const { pathname, query } = router
      delete query.error
      router.replace({ pathname, query }, undefined, { shallow: true })
    }
  }

  const showGlobalLoginPage = () => {
    setEmail('')
    setTenantsToSelect([])
  }

  if (tenantsToSelect.length) {
    return (
      <MultiTenantLogin
        email={email}
        tenantsToSelect={tenantsToSelect}
        isEmailOwnershipVerificationRequired={isEmailOwnershipVerificationRequired}
        onChangeEmailAddressClicked={showGlobalLoginPage}
        onEmailAddressOwnershipVerificationSuccess={() => {
          handleGetTenantsByEmailAddress()
        }}
      />
    )
  }

  return (
    <StyledFormWrapper direction="column" spacing={0}>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2}>
          <LoginSignUpPageHeading />
          <TextField
            label="Email"
            id="email-field"
            placeholder="Enter your email"
            defaultValue={email}
            error={Boolean(error)}
            errorMessage={error || undefined}
            autoComplete="on"
            onChange={(event) => {
              const newValue = event.target.value
              if (newValue !== null && newValue !== undefined) {
                setEmail(newValue.trim())
              }
            }}
          />
          {urlErrorMsg ? renderError(urlErrorMsg) : <></>}
          <SubmitButton label="Continue" loading={isLoading} />
        </Stack>
      </form>
    </StyledFormWrapper>
  )
}

interface MultiTenantLoginProps {
  email: string
  tenantsToSelect: Tenant[]
  isEmailOwnershipVerificationRequired: boolean
  onEmailAddressOwnershipVerificationSuccess: () => void
  onChangeEmailAddressClicked: () => void
}

const MultiTenantLogin = (props: MultiTenantLoginProps) => {
  return props.isEmailOwnershipVerificationRequired ? (
    <EmailOwnershipVerification email={props.email} onSuccess={props.onEmailAddressOwnershipVerificationSuccess} />
  ) : (
    <TenantSelector
      tenants={props.tenantsToSelect}
      userEmailAddress={props.email}
      onChangeEmailAddressClicked={props.onChangeEmailAddressClicked}
    />
  )
}

export default Home
