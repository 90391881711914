interface Config {
  firebase: FirebaseConfig
  loginUrl: LoginUrl
}

interface FirebaseConfig {
  apiKey: string
  authDomain: string
}

interface LoginUrl {
  pocket: string
  hive: string
}

const stagingDomain = 'au-staging.login.papercut.software'
const testDomain = 'au-test.login.papercut.software'
const prodDomain = 'login.papercut.com'
const stagingFirebaseDomain = 'pc-pd-identity-au-staging-1.firebaseapp.com'
const testFirebaseDomain = 'pc-pd-identity-au-test-1.firebaseapp.com'
const prodFirebaseDomain = 'pc-pd-identity-us-prod-1.firebaseapp.com'

const testConfig = {
  firebase: {
    apiKey: 'AIzaSyDqf_Sg1GkXpbds_MRWMP-o66pksZMrKto',
    authDomain: testDomain,
  },
  loginUrl: {
    pocket: 'https://au-staging.pocket.papercut.software/login',
    hive: 'https://au-staging.hive.papercut.software/login',
  },
}
const stagingConfig = {
  firebase: {
    apiKey: 'AIzaSyDOPWlV8H25-eqqDHo7nfm9paF84dIXErw',
    authDomain: stagingDomain,
  },
  loginUrl: {
    pocket: 'https://au-staging.pocket.papercut.software/login',
    hive: 'https://au-staging.hive.papercut.software/login',
  },
}
const prodConfig = {
  firebase: {
    apiKey: 'AIzaSyAM2EGnVwfkCjUeO0O1rAuuxfMnx33oI94',
    authDomain: prodDomain,
  },
  loginUrl: {
    pocket: 'https://pocket.papercut.com/login',
    hive: 'https://hive.papercut.com/login',
  },
}

const localhostConfig = {
  firebase: {
    apiKey: process?.env?.NEXT_PUBLIC_FIREBASE_API_KEY
      ? process.env.NEXT_PUBLIC_FIREBASE_API_KEY
      : 'AIzaSyDOPWlV8H25-eqqDHo7nfm9paF84dIXErw',
    authDomain: process?.env?.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
      ? process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
      : 'localhost:3000', // TODO: Make the port dynamically instead of hard-coded here
    // in order to use firebase auth's redirect auth mode when we authenticate with providers such as Google or Microsoft
    // the browser's window location host of our app must match the authDomain value.
    // Therefore we need to set the authDomain to localhost and proxy the request via nextjs rewrites in next.config.mjs to the real authDomain host
  },
  loginUrl: {
    pocket: `/?product=pocket&redirectUrl=https%3A%2F%2Fau-staging.pocket.papercut.software%2Fauthenticating%3FauthProcess%3Dlogin`,
    hive: `/?product=hive&redirectUrl=https%3A%2F%2Fau-staging.hive.papercut.software%2Fauthenticating%3FauthProcess%3Dlogin`,
  },
}
const allConfigs = new Map<string, Config>([
  ['localhost', localhostConfig],
  ['127.0.0.1', localhostConfig],
  [testDomain, testConfig],
  [testFirebaseDomain, testConfig],
  [stagingDomain, stagingConfig],
  [stagingFirebaseDomain, stagingConfig],
  [prodDomain, prodConfig],
  [prodFirebaseDomain, prodConfig],
])

export const config = (): Config => {
  let hostname = ''
  if (typeof window !== 'undefined') {
    hostname = window.location.hostname
  }

  if (!allConfigs.has(hostname)) {
    throw new Error(`no config found for hostname ${hostname}`)
  }

  return allConfigs.get(hostname)!
}
